((window) => {

  let slides;
  let dots;
  let leftArrowControl;
  let rightArrowControl;
  let nextButtonControl;


  function resetActives() {
    slides.forEach(item => {
      item.classList.remove('feature-show__slide--is-active');
    });

    dots.forEach(item => {
      item.classList.remove('feature-show__controls-dot--is-active');
    });
  }

  function setActiveSlide(index) {
    resetActives();
    slides[index].classList.add('feature-show__slide--is-active');
    dots[index].classList.add('feature-show__controls-dot--is-active');
  }

  function stepLeft() {
    // find active slide
    const index = slides.findIndex(it => it.classList.contains('feature-show__slide--is-active'));

    // check if a slide left of active exist and set it
    if (index > 0) {
      setActiveSlide(index - 1);
    }

    // hide controls
    if (index === 1) {
      leftArrowControl.classList.add('is-hidden');
    }

    // unhide controls
    if (index === slides.length - 1) {
      rightArrowControl.classList.remove('is-hidden');
      nextButtonControl.classList.remove('is-hidden');
    }
  }

  function stepRight() {
    // find active slide
    const index = slides.findIndex(it => it.classList.contains('feature-show__slide--is-active'));

    // check if a slide left of active exist and set it
    if (index < (slides.length - 1)) {
      setActiveSlide(index + 1);
    }

    // hide controls
    if (index === slides.length - 2) {
      rightArrowControl.classList.add('is-hidden');
      nextButtonControl.classList.add('is-hidden');
    }

    // unhide controls
    if (index === 0) {
      leftArrowControl.classList.remove('is-hidden');
    }
  }

  function initFeatureShow(moduleElement) {
    // @ts-ignore
    slides = Array.from(moduleElement.querySelectorAll('.js-slide'));
    // @ts-ignore
    dots = Array.from(moduleElement.querySelectorAll('.js-control-dot'));

    leftArrowControl = moduleElement.querySelector('.js-control-left');
    rightArrowControl = moduleElement.querySelector('.js-control-right');
    nextButtonControl = moduleElement.querySelector('.js-next-button');

    leftArrowControl.addEventListener('click', (event) => {
      // move slides to the left
      stepLeft();
      event.preventDefault();
    });

    rightArrowControl.addEventListener('click', (event) => {
      // move slides to the right
      stepRight();
      event.preventDefault();
    });

    nextButtonControl.addEventListener('click', (event) => {
      stepRight();
      event.preventDefault();
    });

    moduleElement.classList.add(('js-enhanced'));
  }

  const featureShowModules = document.querySelectorAll('.feature-show:not(.js-enhanced)');
  featureShowModules.forEach(initFeatureShow);
})(window);
